<template>
  <div class="form_el">
    <form-component
        :fields="fields"
        typeForm="formOrder"
        @addBlock="addBlock"
        @fromDone="fromDone"
        @deleteGroup="deleteGroup"
    />
  </div>
</template>

<script>
import FormComponent from "@/components/form";


export default {
  name: "form-order",
  components:{FormComponent},
  data() {
    return {
      fields: [
        {
          name: "link",
          label: "Ссылка на товар",
          placeholder: "http://",
          value: "",
          validate: 'required',
          isActive: false,
          isRequired: true,
          type: "input",
          class: ""
        },
        {
          name: "productName",
          label: "Название товара как на сайте",
          placeholder: "Сумка Gucci",
          value: "",
          validate: 'required',
          isActive: false,
          isRequired: true,
          type: "input",
          class: ""
        },
        {
          name: "price",
          label: "Цена",
          placeholder: "500$",
          value: "",
          validate: '',
          isActive: false,
          isRequired: false,
          type: "input",
          class: "w-25"
        },
        {
          name: "size",
          label: "Размер",
          placeholder: "XL",
          value: "",
          validate: '',
          isActive: false,
          isRequired: false,
          type: "input",
          class: "w-25"
        },
        {
          name: "amount",
          label: "Кол-во",
          placeholder: "2",
          value: "",
          validate: '',
          isActive: false,
          isRequired: false,
          type: "input",
          class: "w-25"
        },
        {
          name: "color",
          label: "Цвет",
          placeholder: "черный матовый",
          value: "",
          validate: '',
          isActive: false,
          isRequired: false,
          type: "input",
          class: "w-25"
        },
        {
          name: "comment",
          label: "",
          placeholder: "Комментарий",
          value: "",
          validate: '',
          isActive: false,
          isRequired: false,
          type: "textarea",
          class: "w-50"
        },
        {
          name: "blockSeparator",
          type: "blockSeparator",
          class: 'w-50',
        },
        {
          name: "name",
          label: "ФИО",
          placeholder: "",
          value: "",
          validate: 'required',
          isActive: false,
          isRequired: true,
          type: "input",
          class: ""
        },
        {
          name: "phone",
          label: "Ваш телефон",
          placeholder: "+7  999 999-99-99",
          value: "",
          validate: 'phone',
          isActive: false,
          isRequired: true,
          type: "input",
          class: "w-50"
        },
        {
          name: "email",
          label: "E-mail",
          placeholder: "email@yandex.ru",
          value: "",
          validate: 'email',
          isActive: false,
          isRequired: true,
          type: "input",
          class: "w-50"
        },
        {
          name: "address",
          label: "Адрес доставки",
          placeholder: "",
          value: "",
          validate: 'required',
          isActive: false,
          isRequired: true,
          type: "input",
          class: ""
        },
      ],
      blockCounter: 0
    }
  },
  methods:{
    addBlock(){
      this.blockCounter = this.blockCounter+1;
      const group = [
        {
          name: "link",
          label: "Ссылка на товар",
          placeholder: "http://",
          value: "",
          validate: 'required',
          isActive: false,
          isRequired: true,
          type: "input",
          class: "",
          blockGroup: this.blockCounter,
        },
        {
          name: "productName",
          label: "Название товара как на сайте",
          placeholder: "Сумка Gucci",
          value: "",
          validate: 'required',
          isActive: false,
          isRequired: true,
          type: "input",
          class: "",
          blockGroup: this.blockCounter,
        },
        {
          name: "price",
          label: "Цена",
          placeholder: "500$",
          value: "",
          validate: '',
          isActive: false,
          isRequired: false,
          type: "input",
          class: "w-25",
          blockGroup: this.blockCounter,
        },
        {
          name: "size",
          label: "Размер",
          placeholder: "XL",
          value: "",
          validate: '',
          isActive: false,
          isRequired: false,
          type: "input",
          class: "w-25",
          blockGroup: this.blockCounter,
        },
        {
          name: "amount",
          label: "Кол-во",
          placeholder: "2",
          value: "",
          validate: '',
          isActive: false,
          isRequired: false,
          type: "input",
          class: "w-25",
          blockGroup: this.blockCounter,
        },
        {
          name: "color",
          label: "Цвет",
          placeholder: "черный матовый",
          value: "",
          validate: '',
          isActive: false,
          isRequired: false,
          type: "input",
          class: "w-25",
          blockGroup: this.blockCounter,
        },
        {
          name: "comment",
          label: "Сообщение",
          placeholder: "",
          value: "",
          validate: '',
          isActive: false,
          isRequired: false,
          type: "textarea",
          class: "",
          blockGroup: this.blockCounter,
        },
        {
          name: "border",
          type: "border",
          blockGroup: this.blockCounter,
        }
      ];
          group.forEach(el=>{
        el.name = el.name + this.blockCounter;
      })
      this.fields = [...group, ...this.fields];
      document.querySelector('html').scrollIntoView({
        behavior: 'smooth'
      });
    },
    fromDone(e){
      this.$emit('fromDone',e);
    },
    deleteGroup(e){
      this.fields = this.fields.filter(function (item) {
        return item.blockGroup !== e
      });

    }
  }
}
</script>

<style>
form .btn{
  padding: 13px 24px;
}
#blockSeparator{
  height: 84px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
#blockSeparator .btn{
  background: transparent!important;
  color: #7d7d7e;
  border: 2px solid #7d7d7e;
}
#blockSeparator{

}
.w-25{
  padding: 0 10px 0 0;
}
/*.w-25 .field_border{*/
/*  width: 90%;*/
/*  margin-right: 5%;*/
/*}*/
@media screen and (max-width: 1023px){
  /*.w-25 .field_border{*/
  /*  width: 100%;*/
  /*  margin-left: 0;*/
  /*}*/
  /*.w-25{*/
  /*  padding: 0;*/
  /*}*/
}
</style>
