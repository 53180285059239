<template>
  <form :ref="typeForm" :action="formSend" method="post" class="d-flex flex-wrap ">
    <label v-for="(field,i) in fields" :key="i" :class="field.class" :id="field.name" :data-group="field.blockGroup? field.blockGroup: false">
      <h4 v-if="field.type!=='blockSeparator'" class="black mb-1">{{ field.label }} <span v-if="field.isRequired" class="red">*</span></h4>
      <template v-if="field.type==='input'">
        <input
            class=""
            @focus="field.isActive=true"
            @blur="field.isActive=false"
            @input="fieldInput(field)"
            :name="field.name"
            :placeholder="field.placeholder"
            v-model="field.value"
            :required="field.isRequired"
        >
      </template>
      <template v-if="field.type==='textarea'">
        <textarea
            @input="validate"
            @focus="field.isActive=true"
            @blur="field.isActive=false"
            :name="field.name"
            :placeholder="field.placeholder"
            v-model="field.value"
            rows="1"
            :required="field.isRequired"
        />
      </template>
      <template v-if="field.type==='select'">
        <input
            class=" my_select"
            @click.prevent.stop="field.isOpen = true"
            @focus="field.isActive=true"
            @blur="field.isActive=false"
            :name="field.name"
            v-model="field.value"
            readonly
            :required="field.isRequired"
        >
        <input
            style="height: 0; opacity: 0"
            :required="field.isRequired"
            v-model="field.value"
        >

        <div class="custom_select" :class="{'open': field.isOpen}">
          <div
              class="custom_option d-flex align-items-center"
              v-for="(option,i) in field.options"
              :key="i"
              :id="option"
              @click.stop.prevent="selectInput(field,option)"
          >{{option}}</div>
        </div>
      </template>
      <template v-if="field.type==='blockSeparator'">
        <button class="btn p-2 w-100" @click.prevent="addBlock">Добавить товар <span class="red fw-bold">+</span></button>
      </template>
      <template  v-if="field.type==='border'">
          <div  @click.prevent="$emit('deleteGroup', field.blockGroup)" class="form_border d-flex justify-content-between">
            <span class="red">Удалить товар</span>
            <a href="" class="delete_group fw-bold red" title="Удалить этот товар">+</a>
          </div>
      </template>
      <div v-if="field.type!=='blockSeparator' && field.type !=='border'" class="field_border" :class="{'active': field.isActive}"></div>
    </label>

    <div class="mt-5 d-flex justify-content-between align-items-center w-100">
      <div><button :disabled="!formValid" class="btn" type="submit">
        {{buttonText}}
      </button></div>

        <img class="form_done form_done d-lg-none d-block" v-if="!formValid" src="img/form_default.png" alt="">
        <img class="form_done form_done d-lg-none d-block" v-if="formValid" src="img/form_done.png" alt="">

    </div>
  </form>
</template>

<script>
export default {
  name: "form-component",
  props: ['fields','typeForm'],
  data(){
    return{
      formValid: false
    }
  },
  computed:{
    formSend(){
      switch (this.typeForm){
        case "formContact":
          return 'contact.php';
        case "formOrder" :
          return 'order.php';
        case "formPartner":
          return 'partner.php';
        default:
          return 'services.php';
      }
    },
    buttonText(){
      return  this.typeForm === 'formContact' ? 'Отправить сообщние': 'Отправить заявку'
    }
  },
  methods: {
    fieldInput(field){
      this.onlynumber(field);
      this.validate();
    },
    selectInput(field, option){
      field.value = option;
      field.isOpen = false;
      this.validate();
    },
    onlynumber(field){
      if (field.name === 'phone'){
        let num;
        num = field.value.replace(/\D/g, '');
        field.value = '+'+ num;
        console.log(field.value.length)
      }
    },
    addBlock(){
      this.formValid = false;
      this.$emit('addBlock');
    },
    emailValidate(val){
      let valid = true;
      const step1 = val.split('@');
      if (!step1[1]) valid = false;
      else {
        const step2 = step1[1].split('.');
        if (!step2[1]) valid = false;
        else if (step2[1].length< 2) valid = false
      }
      return valid;
    },
    errorStyle(name){
      this.$refs[this.typeForm].querySelector(`#${name} .field_border`).classList.add('active');
      this.formValid = false;
    },
    validate(){
      this.formValid = true;
      this.fields.forEach((field)=>{
        if (field.isRequired){
          if (field.validate === 'required' && field.value.length < 2){
            this.errorStyle(field.name);
          }
          else if (field.validate === 'phone' && field.value.length !== 12 && field.value.length !== 14){
            this.errorStyle(field.name);
          }
          else if (field.validate === 'email'){
             let valid = this.emailValidate(field.value);
            if(!valid) this.errorStyle(field.name);
          }
        }
        else if (field.validate === 'email' && field.value.length > 0){
          let valid = this.emailValidate(field.value);
          if(!valid) this.errorStyle(field.name);
        }
      });
      if (this.formValid){
        this.$emit('fromDone', true);
      }else this.$emit('fromDone', false);
    },
  }
}
</script>

<style>
.form_done{
  width: 75px;
}
.notification{
  font-size: 15px;
}
h4{
  min-height: 30px;
}
</style>
