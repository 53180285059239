<template>
  <div
      class="form_page pt-lg-5"
  >
    <div
        class="container pt-lg-5"
        :class="{
    'partner': type==='partner',
    'contact': type==='contact',
    'services': type==='services',
    'order': type==='order'
    }"
    >
      <div class="row py-5">
        <div class="col-lg-7 order-1 order-lg-0">
          <form-contact v-if="type==='contact'" @fromDone="fromDone"/>
          <form-order v-if="type==='order'" @fromDone="fromDone"/>
          <form-partner v-if="type==='partner'" @fromDone="fromDone"/>
          <form-services v-if="type==='services'" @fromDone="fromDone"/>
        </div>
        <div class="col-lg-5 px-5 order-0 order-lg-1">
            <div class="d-flex  align-items-start" v-if="type==='partner'"><h2 class="black mt-0"><span class="red">Стать</span><br>
              партнером </h2></div>
            <div class="d-flex  align-items-start" v-if="type==='contact'"><h2 class="black mt-0"><span class="red">Связаться</span><br>
              с нами </h2></div>
            <div class="d-flex  align-items-start" v-if="type==='services'"><h2 class="black mt-0"><span class="red">Заказать</span><br>
              услугу </h2></div>
            <div class="d-flex  align-items-start" v-if="type==='order'">
              <div><h2 class="red mt-0">Заказ</h2>
                <h3 class="black">из интернет-магазина</h3></div>
            </div>
            <div class="pt-5 d-lg-block d-none">
              <img class="check" v-show="!formCompleted" src="img/form_default.png" alt="">
              <img class="check" v-show="formCompleted" src="img/form_done.png" alt="">
            </div>
          <p v-if="type==='contact'" class="d-none mt-5 pt-5 d-lg-inline-block"> Наши операторы всегда готовы <br> ответить на ваши вопросы</p>
        </div>
      </div>
    </div>
    <footer class="pt-5" v-if="type!=='contact'">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-5"><img class="footer_bg" src="img/form_footer.png" alt=""></div>
          <div class="col-md-7">
            <div>
              <h3 class="black">
                Нужна помощь?
              </h3>
              <ul class="list">
                <li>Оставьте заявку на сайте
                </li>
                <li> Наш менеджер свяжется с вами и ответит на все вопросы</li>
              </ul>
              <router-link :to="{name: 'FormPage', params:{formType: 'contact'}}" class="btn">Связаться с нами <i><img
                  src=img/arrow-forward-24-px.svg alt=""></i></router-link>
            </div>
          </div>
        </div>
      </div>

    </footer>
  </div>
</template>

<script>
import FormOrder from "@/components/form-order";
import FormPartner from "@/components/form-partner";
import FormContact from "@/components/form-contact";
import FormServices from "@/components/form-services";

export default {
  name: "FormPage",
  components: {FormOrder, FormPartner, FormContact, FormServices},
  data() {
    return {
      formCompleted: false
    }
  },
  computed: {
    type() {
      return this.$route.params.formType;
    }
  },
  methods: {
    fromDone(e) {
      this.formCompleted = e;
    },
  },
  mounted() {
    document.querySelector('html').scrollIntoView({
      behavior: 'smooth'
    });
  }

}
</script>
<style>
.check{
  width: 195px;
  height: 195px;
}
</style>
