<template>
  <div class="form_el">
  <form-component :fields="fields" typeForm="formPartner" @fromDone="fromDone"/>
  </div>
</template>

<script>
import FormComponent from "@/components/form";

export default {
  name: "form-partner",
  components:{FormComponent},
  data() {
    return {
      fields: [
        {
          name: "name",
          label: "ФИО",
          placeholder: "",
          value: "",
          validate: 'required',
          isActive: false,
          isRequired: true,
          type: "input",
          class: ""
        },
        {
          name: "comment",
          label: "Оказываемые услуги",
          placeholder: "Сообщение",
          value: "",
          validate: 'required',
          isActive: false,
          isRequired: true,
          type: "textarea",
          class: ""
        },
        {
          name: "phone",
          label: "Ваш телефон",
          placeholder: "+7  999 999-99-99",
          value: "",
          validate: 'phone',
          isActive: false,
          isRequired: true,
          type: "input",
          class: ""
        },
        {
          name: "email",
          label: "Электронная почта",
          placeholder: "email@yandex.ru",
          value: "",
          validate: 'email',
          isActive: false,
          isRequired: false,
          type: "input",
          class: ""
        },
      ]
    }
  },
  methods:{
    fromDone(e){
      this.$emit('fromDone',e);
    }
  }
}
</script>

<style scoped>

</style>
