<template>
  <div class="form_el">
    <form-component :fields="fields" @fromDone="fromDone" typeForm="formContact"/>
  </div>
</template>

<script>
import FormComponent from "./form.vue"
export default {
  name: "form-contact",
  components:{FormComponent},
  data() {
    return {
      fields: [
        {
          name: "name",
          label: "ФИО",
          placeholder: "",
          value: "",
          validate: 'required',
          isActive: false,
          isRequired: true,
          type: "input",
          class: ""
        },
        {
          name: "phone",
          label: "Ваш телефон",
          placeholder: "+7  999 999-99-99",
          value: "",
          validate: 'phone',
          isActive: false,
          isRequired: true,
          type: "input",
          class: ""
        },
        {
          name: "email",
          label: "Электронная почта",
          placeholder: "email@yandex.ru",
          value: "",
          validate: 'email',
          isActive: false,
          isRequired: false,
          type: "input",
          class: ""
        },
        {
          name: "comment",
          label: "Комментарий",
          placeholder: "Сообщение",
          value: "",
          validate: '',
          isActive: false,
          isRequired: false,
          type: "textarea",
          class: ""
        },

      ]
    }
  },
  methods:{
    fromDone(e){
      this.$emit('fromDone',e);
    }
  },
  mounted(){
    document.querySelector('html').scrollIntoView({
      behavior: 'smooth'
    });
  },
  created() {
    this.$emit('fromDone',false);
  }

}
</script>

